<template>
  <!--begin::Customer Listing-->
  <div class="customer-template">
    <!-- {{ dataLoading }} -->
    <PageHeaderCount
      moduleType="customer"
      :dataLoading="dataLoading"
      :statusList="statusList"
      allkey="all_customers"
      countkey="customer_status_count"
    ></PageHeaderCount>
    <v-col
      v-if="alphabets.length && false"
      md="12"
      class="alphabets-filter d-flex justify-center"
    >
      <div class="alphabets-scrollable">
        <v-btn
          depressed
          v-on:click="getLineItem(null, 'alphabet')"
          :class="{
            'cyan--text': null == alphabet,
            'cyan white--text': null != alphabet,
          }"
          class="custom-bold-button"
          small
          >All</v-btn
        >
        <v-btn
          depressed
          v-for="(row, index) in alphabets"
          v-on:click="getLineItem(row, 'alphabet')"
          :class="{
            'cyan--text': row == alphabet,
            'cyan white--text': row != alphabet,
          }"
          class="custom-bold-button"
          small
          :key="index"
          >{{ row }}
          <v-badge
            bordered
            color="error"
            class="custom-margin"
            overlap
            dot
            offset-x="10"
            offset-y="10"
          >
          </v-badge>
        </v-btn>
      </div>
    </v-col>
    <ListingTemplate
      :customClass="'customer-listing'"
      v-if="getPermission('customer:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-flex class="pt-0 mxw-200 my-auto">
                <h1 class="form-title d-flex margin-auto selected-rows-text">
                  {{ totalSelected }} Selected
                </h1>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('customer:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(more, index) in customerMoreAction">
                        <v-list-item
                          link
                          v-on:click="updateMoreAction(more.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex class="pt-0 mxw-200">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_customers
                          }}</template>
                          <template v-else>{{
                            item.customer_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-flex>

              <v-spacer></v-spacer>
              <v-flex
                class="pt-0 justify-flex-end d-flex margin-auto all-project-btn-right"
              >
                <v-autocomplete
                  hide-details
                  v-model.trim="filter_type"
                  :items="customerType"
                  clearable
                  style="max-width: 250px !important"
                  dense
                  flat
                  filled
                  placeholder="Customer type"
                  item-color="cyan"
                  color="cyan"
                  solo
                  :disabled="dataLoading"
                  :loading="dataLoading"
                  v-on:change="getRows()"
                  item-value="value"
                  item-text="text"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title
                        v-html="'No Status(s) Found.'"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-list-item-action class="ma-0">
                      <v-chip
                        style="height: 10px; width: 10px; padding: 0"
                        :color="item.color"
                      >
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-content class="py-0">
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                      >
                        <template v-if="item.value == 'all'"
                          >{{ item.text }}
                          <!-- ({{ item.all_ticket_visits }}) --></template
                        >
                        <template v-else
                          >{{ item.text }}
                          <!-- ({{
                          item.ticket_visit_status_count
                        }}) --></template
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-action class="mr-0">
                      <v-chip
                        style="height: 16px; width: 16px; padding: 0"
                        :color="item.color"
                      >
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                        >{{ item.text }}</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action class="align-self-center">
                      <v-list-item-subtitle
                        class="text-lowercase font-weight-500 font-size-14"
                      >
                        <template v-if="item.value == 'all'">{{
                          item.all_ticket_visits
                        }}</template>
                        <template v-else>{{
                          item.ticket_visit_status_count
                        }}</template></v-list-item-subtitle
                      >
                    </v-list-item-action>
                  </template>
                </v-autocomplete>
                <template v-if="getPermission('customer:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button custom-btn-color white--text"
                    :to="getDefaultRoute('customer.create')"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <v-btn
                  v-if="!isEngineerChannel()"
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button btn-tab-hide"
                  color="cyan white--text"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'customer',
                        },
                      })
                    )
                  "
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn>
                <v-menu content-class="custom-menu-list" offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-on:click="exportCustomer"
                    >
                      <v-icon dark left>mdi-database-export</v-icon> Export
                    </v-btn>
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      v-on:click="importDialog = true"
                      color="cyan white--text"
                    >
                      <v-icon dark left>mdi-database-import</v-icon> Import
                    </v-btn>
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                  content-class="white-background"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button btn-tab-hide"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                    v-on:change="updateVisible()"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          hide-details
                          class="mt-0 mb-0"
                          v-on:change="updateVisible()"
                        ></v-checkbox>
                        <v-icon class="draggable-drag-icon" right color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <v-menu v-if="false" offset-y left>
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          :disabled="dataLoading"
                          class="ml-2 custom-bold-button"
                          color="cyan white--text"
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          {{ entity.name }}
                        </v-btn>
                      </template>
                      <span>Entity</span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in entityList"
                      :key="index"
                      link
                      v-on:click="updateEntity(item)"
                    >
                      <v-list-item-title class="font-weight-600">{{
                        item.name
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <PageTips v-if="false" module="customer"></PageTips>
              </v-flex>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Customer"
          :basicSearchFields="[
            'barcode',
            'company_name',
            'display_name',
            'persons_first_name',
            'persons_last_name',
            'persons_display_name',
            'persons_primary_phone',
            'persons_primary_email',
            /*    'properties_unit_no',
            'properties_street_1',
            'properties_street_2',
            'properties_zip_code', */
          ]"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <!-- <pre>{{defaultColDefs}}</pre> -->
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{
                        'checkbox-width-limit': cols.checkbox,
                        'pointer-events-none': lodash.isEmpty(rowData),
                      }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>

                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="!lodash.isEmpty(rowData)">
                  <tr
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                    class="customer-listing-row"
                    :class="{ 'table-in-active-row': !data.activated }"
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        v-on:click="
                          Number(cols.checkbox) == 0
                            ? rowClicked(data)
                            : 'javascript:void(0)'
                        "
                      >
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'activated'">
                          <div
                            class="user-listing-user"
                            style="width: 60px !important"
                          >
                            <v-switch
                              v-model="data.activated"
                              class="mt-0"
                              inset
                              v-on:click.prevent.stop="activeInactive(data)"
                              color="green"
                            ></v-switch>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'image'">
                          <v-img
                            class="customer-image"
                            width="70"
                            height="70"
                            :lazy-src="$defaultProfileImage"
                            :src="getProfileImage(data.profile_logo)"
                          >
                          </v-img>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <Barcode
                            route="customer.detail"
                            :id="data.id"
                            :barcode="data.barcode"
                          ></Barcode>
                          <div>
                            <v-chip
                              small
                              color="blue white--text"
                              label
                              class="mt-1 text-uppercase"
                              v-if="data.company_type == 'company'"
                            >
                              <span class="font-size-16 font-weight-500">
                                Company
                              </span>
                            </v-chip>
                            <v-chip
                              small
                              color="red white--text"
                              label
                              class="mt-1 text-uppercase"
                              v-if="data.company_type == 'personal'"
                            >
                              <span class="font-size-16 font-weight-500">
                                Individual
                              </span>
                            </v-chip>
                          </div>

                          <div
                            v-if="data.transfer_to > 0"
                            class="mt-1 text-uppercase font-size-16 font-weight-500"
                            style="
                              border-color: #ed1c24 !important;
                              background-color: #f44336 !important;
                              color: white;
                              border-radius: 4px;
                              padding: 0px 5px;
                              display: inline-block;
                            "
                          >
                            Ownership<br />Transfered
                          </div>
                        </template>

                        <template v-else-if="cols.field == 'display_name'">
                          <div class="customer-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Reference: </b>
                              <template v-if="data && data.reference">
                                {{ data.reference }}
                              </template>
                              <em v-else class="text-muted"> no reference</em>
                            </p>
                            <p
                              class="m-0 custom-nowrap-ellipsis text-capitalize"
                            >
                              <b>Display Name: </b>
                              <template v-if="data.company_type == 'company'">
                                {{ data.display_name }}</template
                              >
                              <template v-else>{{
                                data.default_person.display_name
                              }}</template>
                            </p>
                            <p class="m-0">
                              <b>Company: </b>
                              <template v-if="data && data.company_name">
                                <b
                                  class="fw-500 text-capitalize"
                                  style="font-weight: 700 !important"
                                >
                                  {{ data.company_name }}</b
                                >
                              </template>
                              <em v-else class="text-muted">
                                no company name</em
                              >
                            </p>

                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Company Email: </b>

                              <template v-if="data && data.company_email">
                                {{ data.company_email && data.company_email }}
                              </template>
                              <em v-else class="text-muted">
                                no company email</em
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Company Phone: </b>
                              <template v-if="data && data.company_number">
                                {{ data.company_number && data.company_number }}
                              </template>
                              <em v-else class="text-muted">
                                no company number</em
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'contact_person'">
                          <div class="customer-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Name: </b>
                              <b
                                class="fw-500"
                                style="font-weight: 700 !important"
                              >
                                <template
                                  v-if="
                                    data &&
                                    data.default_person &&
                                    data.default_person.full_name
                                  "
                                  >{{ data.default_person.full_name }}</template
                                >
                              </b>
                            </p>

                            <p
                              class="m-0 custom-nowrap-ellipsis text-capitalize"
                            >
                              <b>Display Name: </b>
                              {{
                                data.default_person &&
                                data.default_person.display_name
                              }}
                            </p>
                            <!-- <p class="m-0 custom-nowrap-ellipsis">
                              <b>First Name: </b> {{ data.default_person.first_name }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Last Name: </b> {{ data.default_person.last_name }}
                            </p> -->
                            <p
                              class="m-0 custom-nowrap-ellipsis"
                              v-if="
                                lodash.isEmpty(data.default_person) === false
                              "
                            >
                              <b>Email Address: </b>
                              <template
                                v-if="
                                  data &&
                                  data.default_person &&
                                  data.default_person.primary_email
                                "
                              >
                                {{ data.default_person.primary_email }}
                              </template>
                              <em v-else class="text-muted">
                                no email address</em
                              >
                            </p>
                            <p
                              class="m-0 custom-nowrap-ellipsis"
                              v-if="
                                lodash.isEmpty(data.default_person) === false
                              "
                            >
                              <b>Mobile Number: </b>
                              <template
                                v-if="
                                  data &&
                                  data.default_person &&
                                  data.default_person.primary_phone
                                "
                              >
                                {{ data.default_person.primary_phone }}
                              </template>
                              <em v-else class="text-muted">
                                no Mobile Number</em
                              >
                            </p>
                            <template v-if="data.contact_person_count > 1">
                              <v-chip
                                small
                                color="blue white--text"
                                label
                                class="ml-2"
                              >
                                <span class="font-size-16 font-weight-500">
                                  +{{
                                    data.contact_person_count - 1
                                  }}
                                  More</span
                                >
                              </v-chip>
                            </template>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'billing_address'">
                          <div
                            class="property-listing-customer"
                            v-if="lodash.isEmpty(data) === false"
                          >
                            <p class="m-0 custom-nowrap-ellipsis-two-line">
                              <!--  <pre>{{ data }}</pre> -->
                              <b>Site Info Name: </b>
                              <template
                                v-if="
                                  data &&
                                  data.billing &&
                                  data.billing.property_name
                                "
                              >
                                <span>
                                  {{ data.billing.property_name }}
                                </span>
                              </template>
                              <em v-else class="text-muted"> no name</em>
                            </p>

                            <p class="m-0">
                              <!--  <pre>{{ data }}</pre> -->
                              <b>Address: </b>
                              <template
                                v-if="
                                  data && data.billing && data.billing.street_1
                                "
                                >{{ data.billing.street_1 }},</template
                              >

                              <template
                                v-if="
                                  data && data.billing && data.billing.street_2
                                "
                                >{{ data.billing.street_2 }},</template
                              >

                              <template
                                v-if="
                                  data && data.billing && data.billing.unit_no
                                "
                                >{{ data.billing.unit_no }}</template
                              >
                              <br />
                              <template
                                v-if="
                                  data && data.billing && data.billing.country
                                "
                                >{{ data.billing.country }},</template
                              >
                              <template
                                v-if="
                                  data && data.billing && data.billing.zip_code
                                "
                                >{{ data.billing.zip_code }},</template
                              >
                            </p>
                            <template v-if="data.property_count > 1">
                              <v-chip
                                small
                                color="blue white--text"
                                label
                                class="ml-2"
                              >
                                <span class="font-size-16 font-weight-500">
                                  +{{ data.property_count - 1 }} More</span
                                >
                              </v-chip>
                            </template>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'total'">
                          <div class="property-listing-customer">
                            <p class="m-1 mb-1 custom-nowrap-ellipsis">
                              <v-badge
                                color="blue"
                                inline
                                :content="data.door_count"
                              >
                              </v-badge>
                              <b>Door </b>
                            </p>
                            <p class="m-1 mb-1 custom-nowrap-ellipsis">
                              <v-badge
                                color="cyan"
                                inline
                                :content="data.property_count"
                              >
                              </v-badge>
                              <b>Address </b>
                            </p>
                            <p class="m-1 mb-1 custom-nowrap-ellipsis">
                              <v-badge
                                color="green"
                                inline
                                :content="data.quotation_count"
                              >
                              </v-badge>
                              <b>Quotation </b>
                            </p>
                            <p
                              v-if="false"
                              class="m-1 mb-1 custom-nowrap-ellipsis"
                            >
                              <v-badge
                                color="blue"
                                inline
                                :content="data.invoice_count"
                              >
                              </v-badge>
                              <b>Invoice </b>
                            </p>
                            <p class="m-1 mb-1 custom-nowrap-ellipsis">
                              <v-badge
                                color="red"
                                inline
                                :content="data.visit_count"
                              >
                              </v-badge>
                              <b>Visit </b>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDate(data.added_at) }}
                              {{ formatDateSartTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td :colspan="8" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no customer at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>

        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
          routePage
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Customer</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="
                          field == 'customer_detail_phone[value]'
                            ? 'Company Phone'
                            : field == 'customer_detail[value]'
                            ? 'Company Email'
                            : getFilterLabel(field, 'Customer')
                        "
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <ImportTemplate
          :dialog="importDialog"
          sample-url="public/import-samples/glideroleCustomer.xlsx"
          api-url="customernew/import"
          title="Import Customer"
          v-on:reload="getRows()"
          v-on:close="importDialog = false"
        ></ImportTemplate>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Customer Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH, DOWNLOAD, POST } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import ImportTemplate from "@/view/pages/partials/Import-Template.vue";
import JwtService from "@/core/services/jwt.service";
import { saveAs } from "file-saver";
import moment from "moment";

export default {
  mixins: [CommonMixin, ListingMixin],
  name: "customer-list",
  data() {
    return {
      pageTips: false,
      exportLoading: false,
      pageModule: "customer-listing",
      routeAPI: "customer",
      routeName: "customer",
      routeDetailName: "customer.detail",
      status: "all",
      file: "",
      importDialog: false,
      statusList: [],
      customThead: [],
      alphabets: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ],
      moreActions: [
        {
          title: "Import Customer(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },

        /*
        {
          title: "Export Customer(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export",
        },*/
        /*{
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export",
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      customerType: [
        {
          text: "Company",
          value: "company",
          color: "blue accent-4",
        },
        {
          text: "Individual",
          value: "personal",
          color: "red accent-4",
        },
      ],
      customerMoreAction: [
        {
          title: "Mark as Active",
          icon: "mdi-check-all",
          action: "active",
        },
        {
          title: "Mark as In-Active",
          icon: "mdi-check-all",
          action: "inactive",
        },
      ],
    };
  },
  components: {
    draggable,
    PageTips,
    ImportTemplate,
    PageHeaderCount,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
  },
  methods: {
    formatDateSartTime(row) {
      return moment(row).format("hh:mm A");
    },
    getProfileImage(profile_logo) {
      if (profile_logo && profile_logo.file && profile_logo.file.url) {
        return profile_logo.file.url;
      }
      return null;
    },
    activeInactive(data) {
      const _this = this;
      if (data.activated == false) {
        data.activated = 0;
      } else {
        data.activated = 1;
      }
      _this.$store
        .dispatch(PATCH, {
          url: `single-customer/${data.activated}/${data.id}`,
          data: {
            id: data.id,
            status: data.activated,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getRows();
        });
    },
    getBillingAddress(billing) {
      let billingArray = new Array();
      if (this.lodash.isEmpty(billing) === false) {
        if (billing.unit_no) {
          billingArray.push(billing.unit_no);
        }
        if (billing.street_1) {
          billingArray.push(billing.street_1);
        }
        if (billing.street_2) {
          billingArray.push(billing.street_2);
        }
        if (billing.zip_code) {
          billingArray.push(billing.zip_code);
        }
      }
      return billingArray.join(", ");
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkCustomerExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("customer");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    exportCustomer() {
      //console.log(row,"rowhhh");
      //console.log(this.searchableArray,"searchableArray");

      let downloadURL = process.env.VUE_APP_API_URL + "customer/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      //downloadURL.searchParams.append("search", this.queryParams.search);
      this.lodash.map(this.queryParams, function (row, index) {
        downloadURL.searchParams.append(index, row);
      });

      this.lodash.map(this.listingSearch, function (row, index) {
        // console.log(index, "indexdd")

        if (index == "dates") {
          if (row && row[0]) {
            downloadURL.searchParams.append("dates[0]", row[0]);
          }
          if (row && row[1]) {
            downloadURL.searchParams.append("dates[1]", row[1]);
          }
        } else {
          downloadURL.searchParams.append(index, row);
        }

        //  downloadURL.searchParams.append(index, row);
      });
      window.open(downloadURL, "_blank");
    },
    updateMoreAction(param) {
      const _this = this;
      switch (param) {
        case "active":
          _this.bulkCustomerUpdate({
            customers: _this.selectedRows,
            status: 1,
          });
          break;
        case "inactive":
          _this.bulkCustomerUpdate({
            customers: _this.selectedRows,
            status: 0,
          });
          break;
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    importRows() {
      // const _this = this;
      const file = this.$refs.file.files[0];
      if (!file) {
        // alert("No file chosen");
        return;
      }
      const data = new FormData();
      data.append("excel", file);
      const _this = this;
      _this.$store
        .dispatch(POST, {
          url: "customer/import",
          data: data,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
          _this.importDialog = false;
        });
    },
    bulkCustomerUpdate(requestObject) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "customer",
          data: requestObject,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
        });
    },
    bulkCustomerExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "customer/export",
        })
        .then(({ data }) => {
          saveAs(data, "customer-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Customer",
      },
    ]);
    this.getRows();
    // alert(this.defaultColShow.length)
  },
  beforeMount() {
    const _this = this;

    _this.lodash.assign(_this.queryParams, _this.$route.query);

    /* _this.defaultColDefs = [
      {
        headerName: "",
        field: "id",
        sort: null,
        visible: true,
        fixed: true,
        sortable: false,
        checkbox: true,
        order: 1,
      },
      {
        headerName: "Active / In-Active",
        field: "activated",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 2,
      },
      {
        headerName: "Image",
        field: "image",
        sort: null,
        image: true,
        visible: true,
        fixed: false,
        sortable: false,
        order: 3,
      },
      {
        headerName: "Customer #",
        field: "barcode",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 4,
      },
      {
        headerName: "Customer Info",
        field: "display_name",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 5,
      },
      {
        headerName: "Contact Person",
        field: "contact_person",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 6,
      },
      {
        headerName: "Company Address",
        field: "billing_address",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 7,
      },
      {
        headerName: "Total",
        field: "total",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 8,
      },
      {
        headerName: "Created Time",
        child: "created_at",
        field: "added_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 9,
      },
      {
        headerName: "Last Modified Time",
        child: "modified_at",
        field: "updated_at",
        sort: null,
        visible: false,
        fixed: false,
        sortable: false,
        order: 10,
      },
    ]; */

    /* const defaultColDefs = _this.lodash.filter(_this.defaultColDefs, {
      visible: true,
    });

    _this.defaultColShow = defaultColDefs.map((col) => col.field); */

    _this.status = _this.$route.query.status || _this.status;

    // _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
